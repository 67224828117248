export interface ILang {
	[key: string]: {
		text: string;
		lng: 'en' | 'tr';
		icon: string;
		message: string;
	};
}

const LANG: ILang = {
	EN: {
		text: 'English',
		lng: 'en',
		icon: 'CustomUsa',
		message: 'Language changed to English',
	},
	TR: {
		text: 'Türkçe',
		lng: 'tr',
		icon: 'CustomTurkey',
		message: 'Dil Türkçe olarak değiştirildi',
	},
};

export const getLangWithKey = (key: ILang['key']['lng']): ILang['key'] => {
	// @ts-ignore
	return LANG[Object.keys(LANG).filter((f) => key.includes(LANG[f].lng))];
};

export const getOtherLanguage = (key: ILang['key']['lng']): ILang['key'] => {
	// @ts-ignore
	return LANG[Object.keys(LANG).filter((f) => !key.includes(LANG[f].lng))[0]];
};

export default LANG;
