import React, { FC, useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
import Icon from '../../../components/icon/Icon';
import { getLangWithKey, getOtherLanguage, ILang } from '../../../lang';
import showNotification from '../../../components/extras/showNotification';

interface ICommonHeaderRightProps {}
const CommonHeaderRight: FC<ICommonHeaderRightProps> = () => {
	const styledBtn: IButtonProps = {
		color: 'light',
		hoverShadow: 'default',
		isLight: true,
		size: 'lg',
	};

	const { i18n } = useTranslation();

	const changeLanguage = (lng: ILang['key']['lng']) => {
		i18n.changeLanguage(lng).then();
		showNotification(
			<span className='d-flex align-items-center'>
				<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
				<span>{getLangWithKey(lng)?.message}</span>
			</span>,
			'',
		);
	};

	const otherLanguage = useMemo(() => {
		return getOtherLanguage(i18n.language as ILang['key']['lng']);
	}, [i18n.language]);

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
	});

	return (
		<HeaderRight>
			<div className='row g-3'>
				{/* Lang Selector */}
				<div className='col-auto'>
					<Button
						{...styledBtn}
						icon={otherLanguage.icon}
						onClick={() => changeLanguage(otherLanguage.lng)}
					/>
				</div>
			</div>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {};
CommonHeaderRight.defaultProps = {};

export default CommonHeaderRight;
