import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ILogoProps {
	isLogin: boolean;
}
const Logo: FC<ILogoProps> = ({ isLogin }) => {
	if (isLogin) {
		return (
			<svg
				id='katman_1'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='680 250 560 560'
				width={200}
				height={200}>
				<g>
					<path
						fill='#071952'
						d='M940.83,359.06c-17.22,19.77-30.04,42.95-37.44,67.83-15.57-18.57-38.39-29.38-62.97-29.38-21.93,0-42.55,8.56-58.12,24.08-15.52,15.52-24.08,36.19-24.08,58.07,0,9.81-3.8,18.97-10.71,25.88-6.86,6.91-16.07,10.71-25.83,10.71h-4.46c-18.62,0-33.79-15.17-33.79-33.79,0-86.6,71.93-159.74,157.03-159.74h1.45c36.19.35,71.13,13.22,98.92,36.34Z'
					/>
					<path
						fill='#071952'
						d='M1236.57,479.5c0,20.17-16.37,36.74-36.59,36.74h-1.65c-20.27,0-36.54-16.47-36.59-36.69-.05-27.38-13.42-52.71-37.04-68.63-8.56-5.76-18.32-9.76-28.48-11.76-30.04-5.91-59.47,4.41-78.99,26.98-13.22,15.32-19.82,35.29-19.82,55.51v54.06c0,20.67-16.72,37.39-37.39,37.39-10.36,0-19.67-4.21-26.43-10.96-6.76-6.76-10.96-16.07-10.96-26.43v-56.07c0-86.5,70.38-156.93,156.93-156.93,41.9,0,81.35,16.32,111.03,45.95,29.58,29.63,45.95,69.03,46,110.83Z'
					/>
				</g>
				<path
					fill='#37b7c3'
					d='M1147.27,600.05c-15.92,91.11-94.66,157.23-187.27,157.23s-171.4-66.13-187.27-157.23c-1.85-10.71,1.1-21.68,8.11-29.99,7.16-8.51,17.62-13.42,28.78-13.42,8.76,0,17.22,3.05,23.88,8.61,6.76,5.51,11.31,13.32,12.82,21.98,9.71,55.26,57.52,95.36,113.68,95.36s103.97-40.1,113.63-95.31c3.1-17.77,18.57-30.64,36.69-30.64,11.21,0,21.68,4.91,28.78,13.42,7.01,8.31,10.01,19.22,8.16,29.99Z'
				/>
			</svg>
		);
	}
	return (
		<svg
			id='katman_1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='680 250 560 560'
			width={100}
			height={50}>
			<path
				fill='#fff'
				d='M598.69,499.08v67.46c0,9.11-7.44,16.55-16.55,16.55-4.45,0-8.6-1.7-11.71-4.84-3.13-3.13-4.84-7.31-4.84-11.71v-67.46c0-15.43-10.21-27.51-23.26-27.51s-23.23,12.08-23.23,27.51v67.46c0,9.11-7.44,16.55-16.55,16.55s-16.55-7.44-16.55-16.55v-67.46c0-15.43-10.21-27.51-23.26-27.51s-23.21,12.08-23.21,27.51v67.46c0,9.11-7.44,16.55-16.57,16.55-4.42,0-8.6-1.7-11.71-4.84-3.11-3.13-4.84-7.31-4.84-11.71v-67.46c0-34.29,23.21-59.2,55.19-59.2,16.6,0,30.64,6.39,40.8,18.49,10.06-11.96,24.32-18.49,40.51-18.49,32.3,0,55.77,24.91,55.77,59.2Z'
			/>
			<path
				fill='#fff'
				d='M688.55,422c-.02-4.57,1.68-8.8,4.81-11.96,3.09-3.09,7.19-4.79,11.59-4.79,9.21,0,16.4,7.31,16.4,16.65s-7.36,16.43-16.4,16.43c-1.63,0-3.23-.24-4.81-.73-6.8-2.04-11.57-8.46-11.59-15.6Z'
			/>
			<path
				fill='#fff'
				d='M647.36,421.97c-.02-4.54,1.7-8.77,4.81-11.93,3.09-3.09,7.22-4.79,11.62-4.79,9.19,0,16.38,7.31,16.38,16.65,0,4.35-1.73,8.46-4.84,11.57-3.11,3.13-7.22,4.86-11.54,4.86-1.63,0-3.26-.24-4.84-.73-6.8-2.04-11.57-8.48-11.59-15.63Z'
			/>
			<path
				fill='#fff'
				d='M745,461.29v64.62c0,35.94-25.52,62.01-60.63,62.01s-60.61-25.49-60.61-62.01v-64.62c0-9.11,7.41-16.55,16.55-16.55,4.42,0,8.58,1.73,11.71,4.84,3.11,3.13,4.84,7.29,4.84,11.71v64.62c0,17.01,12.08,30.35,27.51,30.35s27.53-13.34,27.53-30.35v-64.62c0-9.11,7.41-16.55,16.55-16.55,4.4,0,8.55,1.73,11.69,4.84,3.13,3.13,4.86,7.29,4.86,11.71Z'
			/>
			<path
				fill='#fff'
				d='M910.67,406.74v109.18c0,40.39-31.37,72-71.44,72s-71.39-31.23-72.03-71.1c-.29-18.74,6.95-36.57,20.39-50.2,13.68-13.9,32.64-21.87,51.98-21.87h25.49v32.81h-25.83c-21.09,0-38.93,18.08-38.93,39.51s17.35,37.91,38.66,38.08c21.04,0,38.64-18.06,38.64-39.44v-108.96c0-9.11,7.41-16.55,16.55-16.55,4.4,0,8.55,1.73,11.69,4.84,3.13,3.13,4.84,7.29,4.84,11.71Z'
			/>
			<path
				fill='#fff'
				d='M1076.41,511.93v54.6c0,9.11-7.41,16.55-16.55,16.55-4.4,0-8.55-1.7-11.69-4.81-3.13-3.16-4.84-7.34-4.84-11.74v-54.41c0-21.36-17.59-39.42-38.39-39.42-21.55.12-38.91,17.2-38.91,38.08s17.81,39.46,38.93,39.46h9.4c9.06,0,16.4,7.36,16.4,16.4,0,4.4-1.7,8.53-4.79,11.62-3.09,3.11-7.22,4.81-11.62,4.81h-9.06c-19.34,0-38.3-7.97-51.98-21.87-13.44-13.66-20.68-31.47-20.39-50.18.63-39.9,32.27-71.15,72.03-71.15s71.44,31.64,71.44,72.05Z'
			/>
			<path
				fill='#fff'
				d='M1227.5,468.6l-51.23,105.15c-2.77,5.69-8.63,9.33-14.92,9.33h-1.12c-6.32,0-12.17-3.67-14.92-9.36l-51.01-105.15c-2.53-5.2-2.19-11.2.87-16.06,3.04-4.86,8.29-7.78,14.02-7.78,3.16,0,6.27.9,8.97,2.6,2.65,1.7,4.76,4.13,6.12,7.02l32.93,71.42c.61,1.34,1.87,2.14,3.33,2.14s2.7-.78,3.33-2.09l33.66-71.56c2.72-5.78,8.63-9.53,15.04-9.53,5.76,0,11.03,2.92,14.07,7.8,3.06,4.86,3.38,10.86.85,16.06Z'
			/>
			<path
				fill='#fff'
				d='M1513.57,499.08v67.46c0,9.11-7.41,16.55-16.55,16.55-4.42,0-8.58-1.7-11.71-4.84-3.11-3.13-4.84-7.31-4.84-11.71v-67.46c0-15.43-10.21-27.51-23.23-27.51s-23.26,12.08-23.26,27.51v67.46c0,9.11-7.41,16.55-16.55,16.55s-16.52-7.44-16.52-16.55v-67.46c0-15.43-10.23-27.51-23.26-27.51s-23.26,12.08-23.26,27.51v67.46c0,9.11-7.41,16.55-16.55,16.55-4.42,0-8.58-1.7-11.69-4.84-3.13-3.13-4.84-7.31-4.84-11.71v-67.46c0-34.29,23.21-59.2,55.19-59.2,16.6,0,30.64,6.39,40.8,18.49,10.06-11.96,24.32-18.49,40.48-18.49,32.32,0,55.77,24.91,55.77,59.2Z'
			/>
			<path
				fill='#fff'
				d='M1292.26,483.33v83.32c0,9.03-7.45,16.48-16.48,16.48-4.52,0-8.58-1.81-11.74-4.97-3.16-3.16-4.74-7.23-4.74-11.52v-83.32c4.97,2.71,10.61,4.29,16.26,4.29s11.74-1.58,16.71-4.29Z'
			/>
			<path
				fill='#37b7c3'
				d='M1300.52,453.1c.05,9.23-4.54,17.45-12.27,21.99-3.79,2.24-8.14,3.4-12.59,3.4s-8.63-1.12-12.34-3.26c-7.85-4.5-12.54-12.73-12.54-22.02,0-13.49,11.4-24.88,24.91-24.88,2.43,0,4.88.36,7.29,1.09,10.3,3.13,17.52,12.88,17.54,23.67Z'
			/>
			<g>
				<path
					fill='#37b7c3'
					d='M1445.85,641.91c.11,7.84-2.95,15.36-8.66,21.12-5.87,5.96-13.98,9.38-22.25,9.38h-3.6c-3.88,0-7.03-3.15-7.03-7.03s3.15-7.05,7.03-7.05h3.99c8.86,0,16.34-7.59,16.34-16.57s-7.48-15.98-16.34-15.98c-.43,0-.84.02-1.27.05-8.32.63-14.82,7.95-14.82,16.66v40.22c0,3.92-3.2,7.09-7.12,7.09s-7.09-3.17-7.09-7.09v-40.47c0-17.11,13.3-30.53,30.3-30.53s30.28,13.26,30.53,30.19Z'
				/>
				<path
					fill='#37b7c3'
					d='M1377.24,642.25v23.07c0,3.92-3.17,7.09-7.09,7.09s-7.09-3.17-7.09-7.09v-22.82c0-8.7-6.53-16.02-14.84-16.66-.41-.02-.84-.05-1.27-.05-8.86,0-16.34,7.32-16.34,15.98s7.48,16.57,16.34,16.57h3.99c3.88,0,7.03,3.17,7.03,7.05s-3.15,7.03-7.03,7.03h-3.6c-8.27,0-16.38-3.42-22.25-9.38-5.69-5.76-8.77-13.28-8.66-21.12.25-16.93,13.66-30.19,30.53-30.19s30.3,13.42,30.3,30.53Z'
				/>
				<path
					fill='#37b7c3'
					d='M1513.54,643.79c-.7,15.52-14.84,28.62-30.89,28.62h-3.6c-3.88,0-7.03-3.15-7.03-7.03s3.15-7.05,7.03-7.05h3.99c8.84,0,16.34-7.59,16.34-16.57s-7.48-15.98-16.34-15.98c-.43,0-.86.02-1.27.05-8.32.63-14.84,7.95-14.84,16.66v40.22c0,3.92-3.17,7.09-7.09,7.09s-7.09-3.17-7.09-7.09v-40.47c0-17.11,13.3-30.53,30.3-30.53,8.41,0,16.23,3.33,22.03,9.38,5.85,6.12,8.86,14.16,8.48,22.68Z'
				/>
			</g>
		</svg>
	);
};
Logo.propTypes = {
	isLogin: PropTypes.bool.isRequired,
};

export default Logo;
