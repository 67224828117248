import React, { FC, FormEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useFormik } from 'formik';
import {
	ConfirmationResult,
	getAuth,
	RecaptchaVerifier,
	signInWithPhoneNumber,
} from 'firebase/auth';
import { getFunctions, httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { PHONE_MASK } from '../../../common/constants';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody, CardHeader } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import Spinner from '../../../components/bootstrap/Spinner';
import { UserData } from '../../../common/data/user-data';
import BusinessContext from '../../../contexts/businessContext';
import getUnmaskedPhone from '../../../common/helper/utils';
import { BusinessData } from '../../../common/data/business-data';
import { AdminData } from '../../../common/data/admin-data';
import { getLangWithKey, getOtherLanguage, ILang } from '../../../lang';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';

interface ResponseData {
	status: number;
	message: string;
	businessData: BusinessData;
	adminData: AdminData;
	businessList: BusinessData[];
}

interface ILoginHeaderProps {
	t: TFunction;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ t }) => {
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>{t('LoginHeader')},</div>
			<div className='text-center h4 text-muted mb-5'>{t('LoginSubText')}</div>
		</>
	);
};

interface CustomWindow extends Window {
	signingIn?: boolean;
	verifyingCode?: boolean;
	confirmationResult?: ConfirmationResult | null;
	recaptchaVerifier?: RecaptchaVerifier;
	recaptchaWidgetId?: number;
}

declare const window: CustomWindow;

const Login = () => {
	const { setUser, setUid } = useContext(AuthContext);
	const [userToLogin, setUserToLogin] = useState<UserData>({} as UserData);
	const { business, setBusiness, setBusinessList } = useContext(BusinessContext);
	const { darkModeStatus } = useDarkMode();
	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const navigate = useNavigate();
	const handleOnClick = useCallback(
		() =>
			navigate(
				userToLogin && userToLogin.adminData && userToLogin.adminData.uid
					? '/admin-dashboard'
					: '/',
			),
		[navigate, userToLogin],
	);
	const auth = getAuth();
	const { t, i18n } = useTranslation('translation');

	const styledBtn: IButtonProps = {
		color: 'light',
		hoverShadow: 'default',
		isLight: true,
		size: 'lg',
	};

	const changeLanguage = (lng: ILang['key']['lng']) => {
		i18n.changeLanguage(lng).then();
		showNotification(
			<span className='d-flex align-items-center'>
				<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
				<span>{getLangWithKey(lng)?.message}</span>
			</span>,
			'',
		);
	};

	const otherLanguage = useMemo(() => {
		return getOtherLanguage(i18n.language as ILang['key']['lng']);
	}, [i18n.language]);

	useEffect(() => {
		try {
			window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
				size: 'invisible',
			});
		} catch (e) {
			console.log('verifier setup error');
			console.log(e);
		}

		try {
			window.recaptchaVerifier!.render().then(function (widgetId) {
				window.recaptchaWidgetId = widgetId;
			});
		} catch (e) {
			console.log('verifier render error');
			console.log(e);
		}

		return () => {
			window.recaptchaVerifier?.clear();
		};
	}, [auth]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			if (values.loginUsername && values.loginUsername) {
				window.verifyingCode = true;
				window
					.confirmationResult!.confirm(formik.values.loginPassword)
					.then(function (result) {
						// User signed in successfully.
						const firebaseUser = result.user;
						setUser({
							uid: userToLogin.uid,
							adminData: userToLogin.adminData,
						});
						if (!userToLogin.uid) {
							setUid(firebaseUser.uid);
							const functions = getFunctions();
							const saveBusinessUid = httpsCallable(functions, 'saveBusinessUid');
							saveBusinessUid({
								uid: business?.uid,
							});
						}
						window.verifyingCode = false;
						window.confirmationResult = null;
						handleOnClick();
					})
					.catch(function (error) {
						// User couldn't sign in (bad verification code?)
						console.error('Error while checking the verification code', error);
						window.alert(
							`${t('VerificationCodeError')} :\n\n ${error.code} \n\n ${error.message}`,
						);
						window.verifyingCode = false;
					});
			}
		},
	});

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleContinue = () => {
		const phoneNumber = getUnmaskedPhone(formik.values.loginUsername);
		const functions = getFunctions();
		const checkBusinessByPhone = httpsCallable(functions, 'checkBusinessByPhone');
		setIsLoading(true);
		checkBusinessByPhone({ phoneNumber })
			.then((result: HttpsCallableResult) => {
				// Read result of the Cloud Function.
				window.signingIn = true;

				const appVerifier = window.recaptchaVerifier;
				signInWithPhoneNumber(auth, phoneNumber, appVerifier!)
					.then(function (confirmationResult) {
						// SMS sent. Prompt user to type the code from the message, then sign the
						// user in with confirmationResult.confirm(code).
						window.confirmationResult = confirmationResult;
						window.signingIn = false;

						const { businessData, adminData, businessList } =
							result.data as ResponseData;
						setUserToLogin({
							uid: businessData?.uid || adminData?.uid,
							adminData,
						});
						setBusiness(businessData);
						setBusinessList(businessList);
						setSignInPassword(true);
						setIsLoading(false);
					})
					.catch(function (error) {
						// Error; SMS not sent
						console.error('Error during signInWithPhoneNumber', error);
						window.alert(
							`${t('SignInError')}:\n\n ${error.code} \n\n ${error.message}`,
						);
						window.signingIn = false;
						setIsLoading(false);
					});
			})
			.catch(({ message }) => {
				formik.setFieldError('loginUsername', message);
				setIsLoading(false);
			});
	};

	const startOver = () => {
		setUserToLogin({} as UserData);
		setBusiness({} as BusinessData);
		window.verifyingCode = false;
		window.confirmationResult = null;
		setSignInPassword(false);
		formik.resetForm();
	};

	return (
		<PageWrapper isProtected={false} title='Login' className='bg-dark'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-end'>
									<Button
										{...styledBtn}
										icon={otherLanguage.icon}
										onClick={() => changeLanguage(otherLanguage.lng)}
									/>
								</div>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}
										aria-label='Facit'>
										<Logo isLogin />
									</Link>
								</div>

								<LoginHeader t={t} />

								<form className='row g-4'>
									<div className='col-12'>
										<FormGroup
											id='loginUsername'
											isFloating
											label={t('PhoneNumber')}
											className={classNames({
												'd-none': signInPassword,
											})}>
											<Input
												autoComplete='username'
												value={formik.values.loginUsername}
												isTouched={formik.touched.loginUsername}
												invalidFeedback={formik.errors.loginUsername}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
												mask={PHONE_MASK}
											/>
										</FormGroup>
										{signInPassword && (
											<div className='text-center h4 mb-3 fw-bold'>
												{t('Hello')},{' '}
												{business?.name || userToLogin?.adminData?.name}.
												<span className='d-block h6 text-muted'>
													{t('EnterSmsCode')}
												</span>
											</div>
										)}
										<FormGroup
											id='loginPassword'
											isFloating
											label='Password'
											className={classNames({
												'd-none': !signInPassword,
											})}>
											<Input
												type='password'
												autoComplete='current-password'
												value={formik.values.loginPassword}
												isTouched={formik.touched.loginPassword}
												invalidFeedback={formik.errors.loginPassword}
												validFeedback='Looks good!'
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										{!signInPassword ? (
											<Button
												color='warning'
												className='w-100 py-3'
												isDisable={!formik.values.loginUsername}
												onClick={handleContinue}>
												{isLoading && <Spinner isSmall inButton isGrow />}
												{t('SendCode')}
											</Button>
										) : (
											<>
												<Button
													color='warning'
													className='w-100 py-3'
													onClick={(e: FormEvent) => {
														e.preventDefault();
														formik.handleSubmit();
													}}>
													{t('VerifyCode')}
												</Button>
												<Button
													color='link'
													className='w-100 py-3'
													onClick={startOver}>
													{t('StartOver')}
												</Button>
											</>
										)}
									</div>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Login;
